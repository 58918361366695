// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps } from "gatsby"
import { Grid, Heading, Text, Box, Divider } from "theme-ui"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SpeakingPage: React.FC<PageProps> = ({ data, path, location }) => (
  <Layout title="Speaking &amp; Conferences" location={location}>
    <Seo title="Speaking &amp; Conferences" />
    <Heading as="h1" sx={{ mb: 4 }}>
      Speaking &amp; Conferences
    </Heading>

    <Text>
      I speak about DevOps, Software Development and Platform Engineering topics
      at virtual and in person events. I will update this section as more get
      published online!
    </Text>

    <Divider sx={{ my: 4 }} />

    <Grid columns={[1, 2]} gap={4}>
      <Box>
        <h2>FinTechNZ - Introducing DevOps practices in FS environments</h2>
        <p>
          Here, I discuss how DevOps is not just about technology. It is the
          fusion of people, process and technology that seperates low and
          high-performing teams. My piece starts at 10m30s.
        </p>
        <iframe
          title="Introducing DevOps practices in FS environments"
          src="https://player.vimeo.com/video/681111259?h=0f6868680c&amp;dnt=1&amp;app_id=122963"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          width="100%"
          height="281"
          frameBorder="0"
        ></iframe>
      </Box>

      <Box>
        <h2>Beta and Beyond Podcast - Software is eating the World</h2>
        <p>
          Justin Mercer and I talk to Nigel Dalton at Thoughtworks about Marc
          Andresson's famous "software is eating the world" quote and what we're
          seeing ten years on.
          <br />
          <br />
        </p>
        <iframe
          title="Ep 18: The 10th Anniversary of ‘Software is eating the world‘ with Nigel Dalton"
          allowTransparency="true"
          scrolling="no"
          data-name="pb-iframe-player"
          src="https://www.podbean.com/player-v2/?from=embed&i=fiji5-10cbbde-pb&share=1&download=1&skin=f6f6f6&btn-skin=8bbb4e&size=150"
          width="100%"
          height="281"
          frameBorder={0}
        ></iframe>
      </Box>

      <Box>
        <h2>
          CHCH .NET User Group - Evolving your Architecture with the Strangler
          Pattern
        </h2>
        <p>
          I discuss Martin Fowler's Strangler Pattern and how it can be used to
          evolve your API architecture piece-by-piece.
        </p>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube-nocookie.com/embed/QtQbtf1flhc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </Grid>
  </Layout>
)

export default SpeakingPage
